@use "../abstracts/variables" as var;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  overflow-x: hidden;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background-color: var.$system-background;

  &.modal-open {
    width: 100%;
    overflow: hidden;
    position: fixed;
  }
}

hr {
  border-color: #fcfdfd;
}

html, body {
  height: 100%;
}

fieldset {
  border: 0;
}