@use "styles/sass/abstracts/variables" as var;

.tooltip-container {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
    border: 1px solid var.$bw-gray-2;
    border-radius: var.$spaces-xs;
    background-color: var.$bw-white;
    padding: var.$spaces-md;
    width: 15rem;

    .tooltip-title {
        color: var.$bw-black !important;
        font-size: var.$fonts-md !important;
        line-height: var.$fonts-md !important;
        padding-bottom: var.$spaces-sm !important;
    }

    .tooltip-body {
        color: var.$bw-gray-1 !important;
        font-size: var.$fonts-sm !important;
        line-height: var.$fonts-sm !important;
    }
}