@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.how-it-works {
  @extend .heading-1;
  text-align: center;
  margin-top: var.$spaces-xxl;
}
.main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cards-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: var.$spaces-xxl;
  max-width: 65rem;
  .card {
    width: 20rem;
    height: 15rem;
    margin: 0 var.$spaces-sm 0 var.$spaces-sm;
    .card-description {
      margin-top: var.$spaces-md;
    }
  }
}
.button-div {
  display: flex;
  justify-content: center;
  .button-begin {
    width: 18rem;
    justify-content: center;
    margin-top: var.$spaces-xxl;
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-banner {
    &.regular {
      height: 10.25rem;
    }
  }

  .how-it-works {
    font-size: var.$fonts-md;
    line-height: var.$fonts-xl;
    margin-top: var.$spaces-lg;
  }
  .card {
    min-height: 10.25rem;
    font-size: var.$fonts-sm;
    line-height: var.$fonts-xl;
    font-weight: var.$weight-regular;
    justify-content: flex-start;
  }
  .card > * + * {
    margin: 0;
  }
  .button-begin {
    margin-top: 2.75rem;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    margin-top: var.$spaces-md;
  }
  .swiper-pagination {
    position: relative;
    bottom: 0 !important;
    margin-top: var.$spaces-md;
  }
  .swiper-pagination-bullet {
    width: var.$spaces-sm;
    height: var.$spaces-sm;
    opacity: 1;
    background: var.$bw-gray-3;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: var.$brand-primary;
  }
  .swiper-slide {
  height: auto;
}
}
