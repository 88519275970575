.card-login {
  a {
    width: 90%;
    align-items: center;
    display: flex;

    .card {
      width: 100%;
      min-height: 0;
      justify-content: unset;
    }
  }
}
