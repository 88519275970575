@use "styles/sass/abstracts/variables" as var;

.summary-header {
  box-shadow: 0 var.$spaces-xs var.$spaces-sm rgba(0, 0, 0, 0.1);
  z-index: 50;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 0 !important;
}
.wrapper-summary {
  width: 100%;
  margin-bottom: 6.25rem;
  bottom: 6.25rem;
  left: 0;
  right: 0;
  .container-summary {
    width: 100vw;
    background-color: var.$bw-white;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: var.$spaces-sm 0 var.$spaces-xl 0;
    bottom: -100%;
    .container-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .content-summary {
      background-color: var.$system-background;
      padding: var.$spaces-md;
      border-radius: var.$spaces-xs;
    }
  }

  .icon {
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
  }

  &.show-summary {
    bottom: 6.25rem;

    .icon {
      transform: rotate(-180deg);
      transition: 0.3s ease-in-out;
    }
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-summary {
    bottom: 5.25rem;

    &.show-summary {
      bottom: 4.55rem;
    }

    .container-summary {
      padding: var.$spaces-md 0;
    }

    .wrapper-stick-footer {
      .container-main {
        padding: 0;
      }
    }
  }
}
