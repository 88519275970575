@use "styles/sass/abstracts/variables" as var;

.wrapper-stick-footer {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  background-color: var.$bw-white;
  box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.04);
  z-index: 10;
  transform: translateX(-50%);
  padding: var.$spaces-md;
}

@media only screen and (max-width: 768px) {
  .wrapper-stick-footer {
    border: none;
  }
}
