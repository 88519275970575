@use 'styles/sass/abstracts/variables' as var;

.wrapper-main {
    width: 100%;
    height: 100vh;
    position: relative;
    padding-top: 3.375rem;

    .container-main {
        max-width: 1280px;
        height: 100%;
        margin: 0 auto;
    }
}

.container-main-bo {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}
