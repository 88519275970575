/*** SPACING ***/
$spaces-xs: 0.25rem; // 4px
$spaces-sm: 0.5rem; // 8px
$spaces-md: 1rem; // 16px
$spaces-lg: 1.5rem; // 24px
$spaces-xl: 2rem; // 32px
$spaces-xxl: 2.5rem; // 40px

$spaces: (
  xs: $spaces-xs,
  sm: $spaces-sm,
  md: $spaces-md,
  lg: $spaces-lg,
  xl: $spaces-xl,
  xxl: $spaces-xxl
);

/*** FONTS ***/
$fonts-xs: 0.75rem; // 12px
$fonts-sm: 0.875rem; // 14px
$fonts-md: 1rem; // 16px
$fonts-lg: 1.125rem; // 18px
$fonts-xl: 1.25rem; // 20px
$fonts-xxl: 1.5rem; // 24px

$fonts: (
  xs: $fonts-xs,
  sm: $fonts-sm,
  md: $fonts-md,
  lg: $fonts-lg,
  xl: $fonts-xl,
  xxl: $fonts-xxl
);

$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$font_weights: (
  regular: $weight-regular,
  medium: $weight-medium,
  semibold: $weight-semibold
);

$font-family-poppins: 'Poppins';

$font_families: (
    poppins: $font-family-poppins
);

$letter-spacing-lg: 0.1rem;
$letter-spacing-md: 0.04em;
$letter-spacing-xs: -0.02em;

/*** COLORS ***/
$brand-primary-dark: #053f61;
$brand-primary: #005383;
$brand-primary-1: #5ca9d6;
$brand-primary-2: #d4eaf7;
$brand-primary-3: #eff8fd;

$bw-white: #fff;
$bw-black: #29363d;
$bw-gray-dark: #5e686e;
$bw-gray-1: #919ba1;
$bw-gray-2: #c8cdd0;
$bw-gray-3: #dde1e4;
$bw-gray-4: #eef0f1;
$bw-gray-5: #fcfdfd;

$system-background: #f8fafc;
$system-success: #5bc880;
$system-error: #ff2640;
$system-alert: #e4b90c;

$colors: (
  brand: (
    primary-dark: $brand-primary-dark,
    primary: $brand-primary,
    primary-1: $brand-primary-1,
    primary-2: $brand-primary-2
  ),
  bw: (
    white: $bw-white,
    black: $bw-black,
    gray-dark: $bw-gray-dark,
    gray-1: $bw-gray-1,
    gray-2: $bw-gray-2,
    gray-3: $bw-gray-3,
    gray-4: $bw-gray-4,
    gray-5: $bw-gray-5
  ),
  system: (
    background: $system-background,
    success: $system-success,
    error: $system-error,
    alert: $system-alert
  )
);

/*** DIRECTIONS ***/
$directions: (
  l: "left",
  r: "right",
  t: "top",
  b: "bottom"
);

/*** LAYOUTS ***/
$header-height: 3.375rem;
