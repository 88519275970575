@use "styles/sass/abstracts/variables" as var;

.container-radio {
  position: relative;
  display: flex;
  align-items: center;

  &.error {
    .styled-radio {
      & + label::before {
        content: "";
        border-color: var.$system-error;
      }
    }
  }

  .styled-radio {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      padding: 0;
      cursor: pointer;
    }

    & + label:before {
      content: "";
      margin-right: var.$spaces-sm;
      display: inline-block;
      vertical-align: text-top;
      width: var.$spaces-md;
      height: var.$spaces-md;
      background: transparent;
      border: 0.125rem solid var.$bw-gray-3;
      border-radius: 50%;
    }

    &:hover:not(:disabled) + label:before {
      box-shadow: 0 0 0 4px var.$brand-primary-2;
      border-color: transparent;
    }

    &:checked + label:before {
      background: var.$brand-primary;
      border-color: var.$brand-primary;
    }

    &:hover:checked + label:before {
      background: var.$brand-primary;
      border-color: var.$brand-primary;
    }

    &:disabled + label {
      color: var.$bw-gray-2;
      cursor: not-allowed;
    }

    &:checked:disabled + label:before {
      background: var.$bw-gray-4;
      border-color: var.$bw-gray-4;
    }

    &:checked + label:after {
      content: "";
      width: var.$spaces-sm;
      height: var.$spaces-sm;
      background: var.$bw-white;
      position: absolute;
      z-index: 1;
      top: 6px;
      left: 6px;
      border-radius: 50%;
    }

    &:checked:disabled + label:after {
      background: var.$bw-gray-2;
    }
  }
}
