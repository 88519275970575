@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";
.verification-page {
  .card {
    max-width: 43rem;
    width: 100%;
    margin: auto;
    box-shadow: none;
    align-items: flex-start;
    text-align: start;
    padding: var.$spaces-xxl;
    margin-top: var.$spaces-xxl;
    .email {
      margin-top: var.$spaces-lg;
      margin-bottom: var.$spaces-lg;
      align-self: center;
    }
    .resend {
      align-self: center;
    }
    .item-list {
      margin-top: var.$spaces-xxl;
    }
    .container-code {
      margin: var.$spaces-xxl 0;
      padding: 0 4.5rem;
      .wrapper {
        margin-bottom: 0;
      }
    }
    .container-buttons {
      margin: auto;
      display: flex;
      flex-direction: column;
      .no-style {
        margin-top: 1.375rem;
        margin-bottom: 0.375rem;
      }
    }
  }
  .container-outside-button {
    display: flex;
    justify-content: center;
    margin-top: 2.375rem;
  }
  .no-style {
    background-color: transparent !important;
  }
}

@media only screen and (max-width: 770px) {
  .verification-page {
    .card {
      border-radius: 0;
      max-width: none;
      width: calc(100% + 2 * var.$spaces-md);
      margin-left: -(var.$spaces-md);
      margin-top: -(var.$spaces-lg);
      padding: var.$spaces-lg var.$spaces-md;
      .item-list {
        margin-top: var.$spaces-md;
      }
      .container-code {
        max-width: 29rem;
        margin: var.$spaces-lg auto;
        padding: 0;
      }
    }
    .container-outside-button {
      margin-top: 1.875rem;
    }
  }
}
