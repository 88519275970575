@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.address-layout {
  display: grid;
  grid-template-rows: 5.75rem auto;
  grid-template-columns: min(688px, max(270px, 75vw));
  gap: var.$spaces-md;

  justify-content: center;
  height: 100%;

  .breadcrumb {
    align-items: center;
    justify-content: center;

    button {
      border: 0;
      background-color: transparent;
      height: var.$spaces-lg;
      width: var.$spaces-lg;

      &:hover {
        cursor: pointer;
      }
    }

    h1 {
      font-size: var.$fonts-xl;
      font-weight: var.$weight-bold;
      color: var.$bw-black;
      letter-spacing: var.$letter-spacing-xs;
    }

    sub {
      font-size: var.$fonts-xs;
      letter-spacing: var.$letter-spacing-md;
      color: var.$bw-gray-dark;
    }
  }

  .form-grid {
    grid-template-rows: 1.25rem repeat(auto-fill, 4rem);
    grid-template-columns: 100%;
    padding: 2rem 5.5rem;
    gap: var.$spaces-md;
  }

  .address-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: var.$spaces-md;

    padding-bottom: 32rem;

    h2 {
      text-align: left;
      color: var.$brand-primary;
      font-weight: var.$weight-regular;
      font-size: var.$fonts-sm;
      line-height: var.$fonts-xl;
    }
  }
}
.container-support-location {
  margin-left: 4%;
  .dropdown-wrapper {
    width: 96%;
  }
  .support-location {
    @extend .body-2;
    margin: var.$spaces-md 0 0 var.$spaces-md;
  }
}
.summary-value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .order-summary-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, var.$fonts-xl);
    gap: var.$spaces-sm;
    padding: var.$spaces-md;
    background-color: var.$system-background;
    border-radius: 4px;

    h2 {
      grid-column: 1 / 3;
      line-height: var.$fonts-xl;
      font-size: var.$fonts-sm;
      font-weight: var.$weight-semibold;
    }

    label,
    span {
      line-height: var.$fonts-xl;
      font-size: var.$fonts-sm;
    }

    .total-name,
    .total-value {
      font-weight: var.$weight-medium;
    }

    .item-value,
    .total-value {
      text-align: end;
    }
  }

  .footer-content {
    min-width: 248px;
    width: 75vw;
    max-width: 688px;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var.$spaces-md;

    button,
    div {
      flex-grow: 1;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 750px) {
  .container-support-location {
    margin-left: 0;
    .dropdown-wrapper {
      width: 100%;
    }
  }
  .address-layout {
    .form-grid {
      padding: 2rem 2.5rem;
    }
  }

  .summary-value {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .address-layout {
    .form-grid {
      padding: 2rem var.$spaces-md;
    }
  }
}
