@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

span.chip {
  @extend .caption-2;
  padding: var.$spaces-xs var.$spaces-sm;
  border-radius: var.$spaces-xs;
  color: var.$bw-white;

  &.chip-neutral{
    color: var.$bw-black;
    background-color: var.$bw-gray-4;
  }

  &.chip-primary {
    background-color: var.$brand-primary;
  }

  &.chip-light {
    background-color: var.$brand-primary-1;
  }

  &.chip-error {
    background-color: var.$system-error;
  }

  &.chip-success {
    background-color: var.$system-success;
  }

  &.chip-alert {
    background-color: var.$system-alert;
  }
}
