@use "styles/sass/abstracts/variables" as var;

.wrapper-banner {
  width: 100%;
  padding: var.$spaces-xl 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #003a5b;

  &.regular {
    height: 21.688rem;
  }

  &.theme-light * {
    color: var.$bw-white;
  }

  &.theme-dark * {
    color: var.$bw-black;
  }

  > .container-main {
    width: 100%;
    height: 100%;
  }

  h2 {
    font-size: 2rem;
    line-height: 1.875rem;
    font-weight: var.$weight-bold;
  }

  p {
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-top: var.$spaces-md;
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-banner {
    padding: var.$spaces-xl 0 var.$spaces-xxl;

    &.regular,
    &.small {
      height: auto;
    }

    h2 {
      font-size: 1.25rem;
      height: 1.875rem;
    }

    p {
      margin-top: var.$spaces-sm;
      font-size: 0.875rem;
      line-height: 1.25rem;

      br {
        display: none;
      }
    }
  }
}
