@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.container-select {
  border: 1px solid var.$bw-gray-4;
  border-radius: 4px;
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.875rem 0.75rem;
  cursor: pointer;
  background-color: var.$bw-white;

  &:not(.container-select-disabled):hover {
    border: 1px solid var.$brand-primary-2;
  }

  &.container-select-checked {
    background-color: var.$brand-primary-2;
    border-color: var.$brand-primary-2;
  }

  &.container-select-error {
    margin-bottom: 0 !important;
    border-color: var.$system-error;
  }

  &.container-select-detail {
    height: 4rem;
  }

  &.container-select-disabled {
    cursor: not-allowed;
  }

  .select-detail {
    @extend .body-2;
    color: var.$bw-gray-dark;
    margin-left: 1.75rem;
  }
}

.error-message {
  @extend .caption-2;
  color: var.$system-error;
  margin: var.$spaces-xs 0 0 var.$spaces-md;
}
