@use "styles/sass/abstracts/variables" as var;

.container-template {
  width: 100vw;
  overflow: auto;
  overflow-x: hidden;

  &.scrollable {
    overflow-y: scroll;
  }
}
