@use '../abstracts/variables' as var;

.color {
    @each $type, $values in var.$colors {
        @each $suffix, $color in $values {
            &--#{$type}-#{$suffix} {
                color: $color;
            }
        }
    }
}

.background-color {
    @each $type, $values in var.$colors {
        @each $suffix, $color in $values {
            &--#{$type}-#{$suffix} {
                background-color: $color;
            }
        }
    }
}
