@use "styles/sass/abstracts/variables" as var;

.container-checkbox {
  position: relative;
  display: flex;
  align-items: center;

  &.error {
    .styled-checkbox {
      & + label::before {
        content: "";
        border-color: var.$system-error;
      }
    }
  }

  .styled-checkbox {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      padding: 0;
      cursor: pointer;
    }

    & + label:before {
      content: "";
      margin-right: var.$spaces-sm;
      display: inline-block;
      vertical-align: text-top;
      width: var.$spaces-md;
      height: var.$spaces-md;
      background: transparent;
      border: 0.125rem solid var.$bw-gray-3;
      border-radius: var.$spaces-xs;
    }

    &:hover:not(:disabled) + label:before {
      box-shadow: 0 0 0 2px var.$brand-primary-2;
      border-color: var.$brand-primary-2;
    }

    &:checked + label:before {
      background: var.$brand-primary;
      border-color: var.$brand-primary;
    }

    &:hover:checked + label:before {
      background: var.$brand-primary;
      border-color: var.$brand-primary;
    }

    &:disabled + label {
      color: var.$bw-gray-2;
      cursor: not-allowed;
    }

    &:checked:disabled + label:before {
      background: var.$bw-gray-4;
      border-color: var.$bw-gray-4;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
      background: var.$bw-white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 var.$bw-white, 4px 0 0 var.$bw-white,
        4px -2px 0 var.$bw-white, 4px -4px 0 var.$bw-white,
        4px -6px 0 var.$bw-white, 4px -8px 0 var.$bw-white;
      transform: rotate(45deg);
    }

    &:checked:disabled + label:after {
      background: var.$bw-gray-2;
      box-shadow: 2px 0 0 var.$bw-gray-2, 4px 0 0 var.$bw-gray-2,
        4px -2px 0 var.$bw-gray-2, 4px -4px 0 var.$bw-gray-2,
        4px -6px 0 var.$bw-gray-2, 4px -8px 0 var.$bw-gray-2;
    }
  }
}
