@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.input-wrapper {
  border: 1px solid var.$bw-gray-3;
  border-radius: var.$spaces-xs;
  display: flex;
  flex-direction: row;
  height: var.$spaces-xxl;
  align-items: center;
  gap: var.$spaces-sm;
  padding: var.$spaces-sm;

  &:hover {
    border: 1px solid var.$brand-primary-2;
  }

  &:focus-within {
    outline: var.$spaces-xs solid var.$brand-primary-2;
    border-color: transparent;
  }

  svg {
    width: var.$spaces-md;
    height: var.$spaces-md;
  }

  input {
    @extend .body-2;
    border: none;
    outline: none;
    width: 100%;

    &::placeholder {
      @extend .body-2;
    }

  }
}
