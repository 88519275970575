@use "styles/sass/abstracts/variables" as var;

.container-header-page {
  display: flex;
  align-items: flex-start;
  margin-bottom: var.$spaces-lg;

  .button {
    width: var.$spaces-lg;
    height: var.$spaces-lg;
    background: transparent;
    border-color: transparent;
    outline: none;
    cursor: pointer;
    margin-top: var.$spaces-xs;

    &:hover {
      opacity: 0.5;
    }
  }
}
