@use "styles/sass/abstracts/variables" as var;

.container-main {
  padding: 0 var.$spaces-md;

  &.full {
    max-width: 100%;
  }

  .main-children-container {
    max-width: 65rem;
    width: 100%;
    margin: 0 auto;
  }
}

.margin-y {
  padding-top: var.$spaces-lg;
  padding-bottom: 7rem;
}
