@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.landing-page-container {
  margin: 4rem;
}

h1 {
  margin-bottom: var.$spaces-xxl;
}

.button-div {
  display: flex;
  justify-content: center;

  .button-begin {
    width: 18rem;
    justify-content: center;
    margin-top: var.$spaces-xxl;
  }
}


.footer-form-code {
  color: var.$bw-gray-3;
  margin-top: var.$spaces-lg;
  text-align: center;
}

@media only screen and (max-width: 770px) {
  .landing-page-container {
    margin: var.$spaces-md 0 0 0;
  }
  .wrapper-banner {
    &.regular {
      height: 10.25rem;
    }
  }

  .button-begin {
    margin-top: 2.75rem;
  }
}
