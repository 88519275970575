@use "../abstracts/variables" as var;

.font-size {
  @each $suffix, $size in var.$fonts {
    &--#{$suffix} {
      font-size: $size;
    }
  }
}

.letter-spacing--lg {
  letter-spacing: var.$letter-spacing-lg;
}

%typography-color {
  color: var.$bw-black;
}

%heading {
  @extend %typography-color;
  font-weight: var.$weight-bold;
}

%body {
  @extend %typography-color;
  font-weight: var.$weight-regular;
}

%button {
  @extend %typography-color;
  font-weight: var.$weight-semibold;
}

.heading-1 {
  @extend %heading;
  font-size: var.$fonts-xl;
  line-height: 1.875rem; //30px
}

.heading-2 {
  @extend %heading;
  font-size: var.$fonts-md;
  line-height: 1.25rem; //20px
}

.body-1 {
  @extend %body;
  font-size: var.$fonts-md;
  line-height: 1.375rem; //22px
}

.body-2 {
  @extend %body;
  font-size: var.$fonts-sm;
  line-height: 1.25rem; //20px
}

.button-1 {
  @extend %button;
  font-size: var.$fonts-md;
  line-height: 1.25rem; //20px
  font-weight: var.$weight-semibold;
}

.button-2 {
  @extend %button;
  font-size: var.$fonts-sm;
  line-height: 1.25rem; //20px
  font-weight: var.$weight-medium;
}

.caption-1 {
  @extend %typography-color;
  font-weight: var.$weight-semibold;
  font-size: var.$fonts-sm;
  line-height: 1.25rem; //20px
}

.caption-2 {
  @extend %typography-color;
  font-weight: var.$weight-regular;
  font-size: var.$fonts-xs;
  line-height: 1rem; //16px
}
