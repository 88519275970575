@use "styles/sass/abstracts/variables" as var;

.wrapper-terms-of-use {
  width: 100%;
  border: 1px solid var.$bw-gray-4;
  border-radius: 4px;
  padding-top: 0.875rem;
  padding-right: 0.75rem;
  padding-bottom: 0.875rem;
  padding-left: 0.75rem;
}
