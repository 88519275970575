@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/layouts/_flexbox.scss";
@import "styles/sass/base/_typography.scss";
.bg-banner {
  background-image: url("/assets/images/bg-portal.png");
  background-repeat: repeat-x;
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    .container-greetings {
      padding: var.$spaces-md;
      .hello {
        font-weight: var.$weight-bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
        color: var.$bw-black;
        margin-top: var.$spaces-lg;
        margin-bottom: var.$spaces-md;
      }
      .welcome {
        font-weight: var.$weight-regular;
        font-size: 1.75rem;
        line-height: var.$fonts-xl;
        color: var.$bw-black;
        margin-bottom: var.$spaces-lg;
      }
    }
    .awaiting-confirmation {
      text-align: center;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
}

.text-modal-primary {
  color: var.$brand-primary !important;
  font-size: var.$fonts-xs;
}

.attention-bg {
  background-color: var.$brand-primary-3;
  border-radius: var.$spaces-sm;
  padding: var.$spaces-sm;
  margin: var.$spaces-md 0;
  border: 1px solid var.$brand-primary-2;
}

.card-request-kit {
  max-width: 32rem;
  .card-title {
    @extend .heading-1;
    margin-top: var.$spaces-lg;
  }
  .card-description {
    @extend .body-2;
    margin-top: var.$spaces-sm;
  }
  .button-request-kit {
    margin-top: var.$spaces-lg;
    max-width: 15.5rem;
    width: 100%;
  }
  .button-resend-email {
    margin-top: var.$spaces-md;
    background-color: transparent !important;
  }
}
.card-next-steps {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: var.$spaces-xxl;
  padding: 0;
  .container-next-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var.$spaces-xxl;
    .card-description {
      margin-top: var.$spaces-md;
      max-width: 15rem;
      width: 100%;
    }
  }
}
.container-informations {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: var.$spaces-xxl;
  row-gap: var.$spaces-lg;
  padding: 0;
  margin-top: var.$spaces-xxl;
  .information {
    height: fit-content;
    padding: var.$spaces-md var.$spaces-lg;
    align-items: flex-start;
    box-shadow: none;
    .title {
      @extend .caption-1;
      margin-bottom: var.$spaces-md;
    }
  }
  .information.analysis {
    width: 100%;
    max-width: 41.5rem;
    .container-chips {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: var.$spaces-md 0 var.$spaces-sm 0;
      .chip:last-child {
        margin-right: 0;
      }
      .chip {
        margin: 0 0.75rem var.$spaces-sm 0;
      }
    }
    .container-image {
      width: 100%;
      background-color: var.$system-background;
    }
    .description {
      @extend .body-2;
      text-align: start;
      margin-top: var.$spaces-sm;
      margin-bottom: var.$spaces-md;
    }
  }
  .information.event {
    width: fit-content;
  }
}
.card > * + * {
  margin: 0;
}
.p-nextsteps {
  @extend .caption-1;
  margin-top: var.$spaces-xxl;
  margin-bottom: var.$spaces-md;
}
.container-questions {
  display: flex;
  flex-direction: row;
  width: 23.2rem;
  background-image: linear-gradient(
    92.81deg,
    rgba(0, 58, 91, 0.965395) 4.02%,
    #005383 51.24%,
    rgba(0, 85, 134, 0.9) 73.25%
  );
  box-shadow: 0 var.$spaces-xs var.$spaces-sm rgba(0, 0, 0, 0.1);
  border-radius: 6.25rem 0 0 6.25rem;
  padding: 1.125rem 0 1.125rem var.$spaces-xxl;
  position: fixed;
  right: -16.875rem;
  bottom: 3.5rem;
  transition: transform 250ms;
  .icon {
    margin-right: var.$spaces-xl;
  }
}
.container-questions:hover {
  transform: translateX(-16.875rem);
}

.price-table-modal-wrapper {
  background-color: var.$bw-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  padding: var.$spaces-lg 0;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 75vw;
  border-radius: var.$spaces-sm;

  .scrollable-content {
    overflow-y: auto;
    margin-bottom: var.$spaces-lg;
    padding: 0 var.$spaces-xxl;
  }

  &.mobile {
    max-width: 95vw;
  }
}

@media only screen and (max-width: 770px) {
  .bg-banner {
    background-image: url("/assets/images/bg-portal-mobile.png");
    background-size: 100% 70%;
    .banner-content {
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      .container-greetings {
        padding: 0;
        .hello {
          font-size: var.$fonts-xl;
          line-height: 1.875rem;
          margin-bottom: var.$spaces-xs;
        }
        .welcome {
          font-weight: var.$weight-semibold;
          font-size: var.$fonts-sm;
          line-height: var.$fonts-xl;
        }
      }
      .awaiting-confirmation {
        text-align: center;
      }
    }
  }

  .card-request-kit {
    max-width: none;
    .card {
      width: 100%;
      height: fit-content;
      .card-title {
        font-size: var.$fonts-md;
        line-height: var.$fonts-xl;
        margin-top: var.$spaces-lg;
      }
      .card-description {
        margin-top: var.$spaces-sm;
      }
      .button-request-kit {
        margin-top: var.$spaces-md;
      }
    }
  }
  .card-next-steps {
    row-gap: var.$spaces-xl;
    flex-direction: column;
    width: 100%;
    padding: var.$spaces-lg;
    .container-next-steps {
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      .card-description {
        margin-left: 0.75rem;
        margin-top: 0;
        text-align: start;
      }
    }
  }
  .container-informations {
    row-gap: 0;
    .information {
      border-radius: 0;
      margin-bottom: var.$spaces-lg;
    }
    .information.analysis {
      width: 100%;
      hr {
        width: 100%;
        border: 0;
        border-top: 0.125rem solid var.$bw-gray-3;
      }
      .btn {
        align-self: center;
        margin-top: 0.75rem;
        width: 100%;
      }
    }
    .information.event {
      width: 100%;
      .container-image {
        margin: auto;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .ignore-padding-mobile {
    width: calc(100% + 2 * var.$spaces-md);
    margin-left: -(var.$spaces-md);
  }
  .container-template {
    padding-bottom: 0;
  }
}
