@use "styles/sass/abstracts/variables" as var;

.container-questions {
  display: flex;
  flex-direction: row;
  width: 23.2rem;
  background-image: linear-gradient(
    92.81deg,
    rgba(0, 58, 91, 0.965395) 4.02%,
    #005383 51.24%,
    rgba(0, 85, 134, 0.9) 73.25%
  );
  box-shadow: 0 var.$spaces-xs var.$spaces-sm rgba(0, 0, 0, 0.1);
  border-radius: 6.25rem 0 0 6.25rem;
  padding: 1.125rem 0 1.125rem var.$spaces-xxl;
  position: fixed;
  right: -16.875rem;
  bottom: 3.5rem;
  transition: transform 250ms;
  .icon {
    margin-right: var.$spaces-xl;
  }
}
.container-questions:hover {
  transform: translateX(-16.875rem);
}
@media only screen and (max-width: 770px) {
  .container-questions {
    left: 0;
    bottom: 0;
    justify-content: center;
    width: 100%;
    border-radius: 0;
    padding: 1rem 0;
    .icon {
      margin-right: 0.75rem;
    }
  }

  .container-questions:hover {
    transform: none;
  }
}
