@use "styles/sass/abstracts/variables" as var;

.container-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: var.$spaces-xxl;
    height: 3.5rem;
    background: var.$bw-white;
    text-align: center;
    appearance: none;
    margin: 0;
    font-size: var.$fonts-xl;
    line-height: 1.875rem;
    font-weight: var.$weight-bold;
    box-shadow: 0px var.$spaces-xs var.$spaces-sm rgba(0, 0, 0, 0.1);
    border-radius: var.$spaces-sm;
    border: 2px solid transparent;
    outline: none;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      border-color: var.$brand-primary-2;
    }
  }

  &.error {
    input {
      border-color: var.$system-error;
    }

    p {
      text-align: left;
      color: var.$system-error;
    }
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }
}
