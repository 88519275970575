@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

button.btn {
  padding: var.$spaces-xs 0.75rem;
  border-radius: var.$spaces-xs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 2px solid #dde1e4; /* Light grey */
    border-top: 2px solid #005383; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  * {
    vertical-align: middle;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  //size
  &.btn-regular {
    @extend .button-1;
    height: var.$spaces-xxl;
  }

  &.btn-small {
    @extend .button-2;
    height: var.$spaces-xl;
  }

  &.btn-icon {
    justify-content: center;
    padding: 0;
  }

  &.btn-small-icon {
    width: var.$spaces-xl;
  }

  &.btn-regular-icon {
    width: var.$spaces-xxl;
  }

  //variant
  &.btn-primary {
    background-color: var.$brand-primary;
    border: none;
    color: var.$bw-white;

    .icon * {
      fill: var.$bw-white;
    }

    &:disabled {
      background-color: var.$bw-gray-4;
      color: var.$bw-gray-2;

      .icon * {
        fill: var.$bw-gray-2;
      }
    }

    &:hover:not([disabled]) {
      background-color: var.$brand-primary-1;
    }
  }

  &.btn-secondary {
    background-color: var.$bw-white;
    border: 1px solid var.$brand-primary;
    color: var.$brand-primary;

    .icon * {
      fill: var.$brand-primary;
    }

    &:disabled {
      background-color: var.$bw-gray-4;
      border: 1px solid var.$bw-gray-2;
      color: var.$bw-gray-2;

      .icon * {
        fill: var.$bw-gray-2;
      }
    }

    &:hover:not([disabled]) {
      border: 1px solid var.$brand-primary-1;
      color: var.$brand-primary-1;

      .icon * {
        fill: var.$brand-primary-1;
      }
    }
  }

  &.btn-tertiary {
    background-color: var.$bw-white;
    border: none;
    color: var.$brand-primary;

    .icon * {
      fill: var.$brand-primary;
    }

    &:disabled {
      background-color: var.$bw-gray-4;
      color: var.$bw-gray-2;

      .icon * {
        fill: var.$bw-gray-2;
      }
    }

    &:hover:not([disabled]) {
      color: var.$brand-primary-1;

      .icon * {
        fill: var.$brand-primary-1;
      }
    }
  }

  &.btn-full-width {
    width: 100%;

    span {
      flex: 1;
      text-align: center;
    }
  }
}

button > * + * {
  margin-left: var.$spaces-sm;
}
