@use "styles/sass/abstracts/variables" as var;

.wrapper-form {
  max-width: 37.5rem;
  background-color: var.$bw-white;
  padding: var.$spaces-xxl 5.5rem;
  border-radius: var.$spaces-xs;
  margin: 0 auto;

  &.full {
    max-width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .wrapper-form {
    padding: var.$spaces-md;
  }
}
