@use "styles/sass/abstracts/variables" as var;

.card {
  padding: var.$spaces-lg;
  background-color: var.$bw-white;
  border-radius: var.$spaces-sm;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card > * + * {
  margin: var.$spaces-xs;
}
