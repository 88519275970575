@use "styles/sass/abstracts/variables" as var;

.modal-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    width: 100vw;
    height: 100vh;

}

.modal-bg {
    background-color: var.$bw-black;
    opacity: 0.5;
}

.modal-children-wrapper {
    z-index: 100001;
}

.hidden {
    display: none;
}