@use "../abstracts/variables" as var;

.Toastify__toast {
  min-height: 37px;
  max-width: 327px;
  width: 100%;
  margin: var.$spaces-md auto;
  border-radius: 4px;
  background-color: var.$bw-gray-dark;
  padding: var.$spaces-sm var.$spaces-md;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}
