@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/layouts/_flexbox.scss";
@import "styles/sass/base/_typography.scss";
.background {
  background-image: url("/assets/images/bg-portal.png");
  background-repeat: repeat-x;
  background-size: 100% 50%;
  .card {
    max-width: 43rem;
    margin: var.$spaces-xl auto;
    padding: 3rem var.$spaces-lg;
    .container-content {
      max-width: 22rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .card-description {
        @extend .body-2;
        margin: var.$spaces-md 0;
        max-width: 18.125rem;
      }
      .form-document {
        width: 100%;
        .wrapper-input {
          width: 100%;
        }
        .btn {
          width: 100%;
          margin-top: var.$spaces-md;
        }
      }
    }
  }
}

@media only screen and (max-width: 770px) {
  .bg-banner {
    .card {
      padding: var.$spaces-lg;
    }
  }
}
