@use "styles/sass/abstracts/variables" as var;

.wrapper-input {
  .container-input {
    position: relative;
    background-color: var.$bw-gray-5;

    &.disabled {
      background-color: var.$bw-gray-4;
      border-radius: var.$spaces-xs !important;

      * {
        cursor: not-allowed;
      }

      label,
      label.filled {
        color: var.$bw-gray-2;
        border-color: var.$bw-gray-4;
        border-radius: var.$spaces-xs !important;

        .icon {
          path {
            fill: var.$bw-gray-2;
          }
        }

        span,
        input {
          color: var.$bw-gray-2;
        }
      }
    }

    &.error {

      label,
      label.filled {
        border: 1px solid transparent;
        border-bottom: 1px solid var.$system-error;

        span {
          color: var.$system-error;
        }
      }
    }

    label {
      width: 100%;
      position: relative;
      height: 3.75rem;
      display: flex;
      border: 2px solid transparent;
      border-bottom: 2px solid var.$bw-gray-3;
      padding: 1.2rem var.$spaces-md 0.7rem var.$spaces-md;
      align-items: center;

      &:focus-within {
        border-color: var.$brand-primary-2;
        border-radius: var.$spaces-xs;
        margin-top: 0;

        span {
          color: var.$brand-primary;
          letter-spacing: 0.04em;
        }

        .w-input .tooltip-selector .icon {
          width: 0.75rem;
          height: 0.75rem;
          top: 1.2rem;
          transform: translateY(-50%);

          path {
            stroke: var.$brand-primary;
          }
        }
      }

      &.filled {
        span {
          top: 0.955rem;
          color: var.$bw-gray-dark;
          font-size: var.$fonts-xs;
          line-height: var.$fonts-xs;
          letter-spacing: 0.04em;
        }
      }

      &.isPass {
        padding-right: 3rem;

        input {
          padding-right: 3rem;
        }
      }

      .w-input {
        width: 100%;
        display: flex;
      }

      .icon {
        transition: 0.2s;
        position: absolute;
        right: 0;

        path {
          fill: var.$brand-primary;
        }
      }

      .nofill {
        path {
            fill: none !important;
          }
      }

      span {
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0.2rem 0 0 0;
        padding: 0;
        font-size: var.$fonts-sm;
        line-height: var.$fonts-sm;
        transition: top 0.2s, font-size 0.2s, color 0.2s;
        padding-right: 0rem;
        color: var.$bw-black;
      }

      .reduced-width {
        width: 85%;
      }

      input {
        width: 100%;
        height: fit-content;
        border: none;
        font-size: var.$fonts-sm;
        line-height: var.$fonts-xl;
        background: transparent;
        background-color: var.$bw-gray-5;
        color: var.$bw-black;
        letter-spacing: initial;
        margin-top: 0.95rem;
        padding-right: var.$spaces-xxl;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0 30px var.$bw-gray-5 inset !important;
          -webkit-text-fill-color: var.$bw-black !important;
        }

        &:focus-visible {
          outline: none;
        }

        &:focus+span {
          top: 0.955rem;
          color: var.$brand-primary;
          font-size: var.$fonts-xs;
          line-height: var.$fonts-xs;
        }
      }

      button {
        background-color: transparent;
        border: none;
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
        outline: none;
        position: absolute;
        right: var.$spaces-md;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
      }
    }
  }

  p.text {
    font-size: var.$fonts-xs;
    line-height: var.$fonts-xs;
    margin: var.$spaces-xs var.$spaces-md 0 var.$spaces-md;

    &.error {
      color: var.$system-error;
    }

    &.helper {
      color: var.$bw-gray-1;
    }
  }

  .tooltip-wrapper {
    position: relative;
  }

  .tooltip-container span {
    position: static !important;
    transform: translateY(0) !important;
  }

  .tooltip-selector:hover .tooltip-wrapper .tooltip-container {
    visibility: visible;
    opacity: 1;
  }
}