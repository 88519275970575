@use "styles/sass/abstracts/variables" as var;
$transition: transform 0.2s ease-out;

.collapsible {


  .collapsible-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var.$bw-white;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .icon {
      transform: rotate(0deg);
      transition: $transition;
    }

    .icon.collapsible-icon-open {
      transform: rotate(-180deg);
      transition: $transition;
    }

  }

}
