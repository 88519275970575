@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

$transition: transform 0.2s ease-out;

.dropdown-wrapper {
  border-radius: var.$spaces-xs var.$spaces-xs 0 0;
  position: relative;

  &:focus,
  &:focus-within,
  &:active {
    .dropdown-input {
      border: 2px solid var.$brand-primary-2 !important;
      border-radius: var.$spaces-xs;
    }
  }

  &.dropdown-wrapper-error {
    .dropdown-input {
      border-bottom: 1px solid var.$system-error;

      .dropdown-label {
        color: var.$system-error;
      }
    }
  }

  .dropdown-input {
    min-height: 3.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    background-color: var.$bw-gray-5;
    border: 2px solid transparent;
    border-bottom: 2px solid var.$bw-gray-3;

    &.dropdown-input-open {
      border-color: var.$brand-primary-2 !important;
      border-radius: var.$spaces-xs;
    }

    &.disabled {
      border: 0 !important;
    }

    &:hover {
      border-bottom: 2px solid var.$brand-primary-2;
    }

    svg {
      transform: rotate(0deg);
      transition: $transition;
    }

    svg.dropdown-icon-open {
      transform: rotate(-180deg);
      transition: $transition;
    }

    .dropdown-label {
      @extend .body-2;
      top: 50%;
      transform: translate(0, -50%);
      transition: top 0.2s, font-size 0.2s, color 0.2s;

      &.dropdown-label-filled {
        @extend .caption-2;
        top: 1rem;
      }
    }

    .dropdown-value {
      top: 1.75rem;
      font-size: var.$fonts-sm;
      line-height: var.$fonts-xl;
      color: var.$bw-black;
      letter-spacing: initial;
    }
  }

  .dropdown-container {
    background: var.$bw-white;
    border: 1px solid var.$bw-gray-4;
    box-shadow: 0px var.$spaces-xs 8px rgba(0, 0, 0, 0.1);
    border-radius: var.$spaces-xs;
    padding: var.$spaces-md;
    max-height: 12.5rem;
    position: absolute;
    z-index: 11;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    .dropdown-option {
      @extend .body-2;
      border-bottom: 2px dashed var.$bw-gray-4;
      padding: var.$spaces-sm;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }

    .dropdown-option:last-child {
      border-bottom: none;
    }

    .selected-dropdown-option {
      color: var.$brand-primary;
      background-color: var.$bw-gray-5;
    }
  }

  .dropup-container {
    bottom: 3.75rem;
  }

  p.text {
    font-size: var.$fonts-xs;
    line-height: var.$fonts-xs;
    margin: var.$spaces-xs var.$spaces-md 0 var.$spaces-md;

    &.error {
      color: var.$system-error;
    }

    &.helper {
      color: var.$bw-gray-1;
    }
  }
}