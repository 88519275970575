@use "styles/sass/abstracts/variables" as var;

.bg-banner-success {
  background-image: url("/assets/images/bg-banner-success.png");
  background-repeat: repeat-x;
}

.card-success {
  max-width: 688px;
  margin: 0 auto;
  padding: 3rem 2rem;

  .card-content {
    width: 100%;
    max-width: 350px;
  }
}
