@use "styles/sass/abstracts/variables" as var;

.container-columns {
  width: 100%;
  display: flex;
  position: relative;

  .sidebar-image {
    height: 100%;
    background: url("../../assets/images/bg-sidebar.png") bottom center
      no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 4rem 7.5rem;
    flex: 1 1 46.875rem;

    h2,
    p {
      color: var.$bw-white;
    }
  }

  .container-page {
    flex: 1;
    padding: 4rem 4rem 4rem 51.875rem;
    justify-content: center;
  }
}

@media only screen and (max-width: 1920px) {
  .container-columns {
    .sidebar-image {
      max-width: 39vw;
      flex: 1 1 39vw;
    }

    .container-page {
      padding-left: calc(39vw + 4rem);
    }
  }
}

@media only screen and (max-width: 1366px) {
  .container-columns {
    .sidebar-image {
      max-width: 30vw;
      flex: 1 1 30vw;
      padding: 4rem;
    }

    .container-page {
      padding-left: calc(30vw + 4rem);
    }
  }
}

@media only screen and (max-width: 1100px) {
  .container-columns {
    .sidebar-image {
      max-width: 30vw;
      flex: 1 1 30vw;
      padding: var.$spaces-xxl;
    }

    .container-page {
      padding: var.$spaces-xxl var.$spaces-xxl var.$spaces-xxl
        calc(30vw + var.$spaces-xxl);
    }
  }
}

@media only screen and (max-width: 770px) {
  .container-columns {
    height: auto;
    flex-direction: column;

    .sidebar-image {
      width: 100%;
      max-width: 100%;
      position: relative;
      background-image: url("../../assets/images/bg-sidebar-mobile.jpeg");
      padding: var.$spaces-xxl var.$spaces-md 4.375rem;

      img {
        width: 7.5rem;
      }
    }

    .container-page {
      padding: var.$spaces-xxl var.$spaces-md;
      justify-content: flex-start;
    }
  }
}
