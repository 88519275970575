@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.item-list {
  height: 4.5rem;
  border: 1px solid var.$bw-gray-4;
  border-radius: var.$spaces-xs;
  padding: var.$spaces-md;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .item-list-prefix {
    width: 2.25rem;
    height: 2.25rem;
  }

  &:hover {
    border: 1px solid var.$brand-primary-2;
    background-color: var.$bw-gray-5;
  }

  .item-list-prefix + .item-list-content {
    margin-inline: var.$spaces-sm;
  }

  .item-list-content {
    display: flex;
    flex-direction: column;
    align-content: center;

    .item-list-title {
      @extend .button-1;
      color: var.$brand-primary;
    }

    .item-list-detail {
      @extend .caption-2;
      color: var.$bw-gray-dark;
    }

    .item-list-title + .item-list-detail {
      margin-block: var.$spaces-xs;
    }
  }

  .item-list-sufix {
    margin-left: auto;
  }
}
