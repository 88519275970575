@use "styles/sass/abstracts/variables" as var;
@import "styles/sass/base/_typography.scss";

.header {
  box-shadow: 0 var.$spaces-xs var.$spaces-sm rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  max-height: 4rem;
  background-color: var.$bw-white;
  z-index: 50;
  padding: 0.75rem var.$spaces-md;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    display: flex;
    align-items: center;
    img {
      height: var.$spaces-xl;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .cart-notification {
      margin-left: -(var.$spaces-sm);
      height: var.$spaces-sm;
      width: var.$spaces-sm;
      background-color: #ff8126;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .container-menu-dropdown {
    position: relative;
    display: inline-flex;

    .user-name {
      @extend .body-1;
      padding: 0.6rem;
      font-weight: bold;
    }

    .menu-dropdown {
      position: absolute;
      top: 30px;
      right: 0;
      display: none;
      width: 160px;
      background-color: var.$bw-white;

      button {
        width: 100%;
      }
    }

    &:hover {
      .menu-dropdown {
        display: block;
      }
    }
  }
}
