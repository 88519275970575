@use "styles/sass/abstracts/variables" as var;

.support-location-wrapper {

  background-color: var.$bw-white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: var.$spaces-md;

  font-size: var.$fonts-sm;
  line-height: var.$fonts-xl;

  padding: var.$spaces-md;

  .support-location {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 3rem;

    .support-location-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      label {
        font-size: var.$fonts-xs;
        letter-spacing: var.$letter-spacing-md;
        color: var.$bw-gray-dark;
      }

      span {
        color: var.$bw-black;
      }
    }

    .support-location-map {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }

  .divider {
    height: 1px;
    background-color: var.$bw-gray-3;
  }

  .support-location-alter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .support-location-wrapper {
    .support-location {
      flex-direction: column;
      justify-content: flex-start;
      gap: var.$spaces-md;
    }
  }
}